import queryString from 'query-string';
import React from 'react';

import { ActivationSurvey } from '../components';
import SEO from '../components/seo';

const realEstateSatisfactionSurvey = (props) => {
  let param = queryString.parse(props.location.search);

  return (
    <>
      <SEO title="Pesquisa de Ativação" />
      <ActivationSurvey personID={param.z} />
    </>
  );
};

export default realEstateSatisfactionSurvey;
